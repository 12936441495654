import React from "react";
import Main from "./components/Main";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ClockmasterPrivacy from "./components/clockmaster/Index";
import NotesdeckPrivacy from "./components/notesdeck/Index";
import SoundscapePrivacy from "./components/soundscape/Index";
import FinancecalculatorPrivacy from "./components/financecalculator/Index";


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route
          path="/clockmaster/privacypolicy"
          element={<ClockmasterPrivacy />}
        />
        <Route path="notesdeck/privacypolicy" element={<NotesdeckPrivacy />} />
        <Route
          path="soundscape/privacypolicy"
          element={<SoundscapePrivacy />}
        />
        <Route
          path="financecalculator/privacypolicy"
          element={<FinancecalculatorPrivacy />}
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
