import React, { useState } from "react";
import "./Main.css";
import bg1 from "../assets/img/bg1.svg";
import lmob from "../assets/img/l-mob.png";
import rmob from "../assets/img/r-mob.png";
import ss1L from "../assets/img/ss-shape1L.png";
import ss1R from "../assets/img/ss-shape1R.png";
import soundscape from "../assets/img/soundscape.png";
import handMob from "../assets/img/hand-mob.png";
import calLogo from "../assets/img/calLogo.png";
import calPhone from "../assets/img/cal-phone.png";
import signimg from "../assets/img/signing.png";
import decision from "../assets/img/decision.png";
import bank from "../assets/img/bank.png";
import atm from "../assets/img/atm.png";
import money from "../assets/img/money.png";
import payment from "../assets/img/payment-method.png";
import googlePlay from "../assets/img/googlePlay.png";
import clockMaster from "../assets/img/clockMaster.png";
import NotesDeckText from "../assets/img/NotesDeckText.png";
import girlimg from "../assets/img/girlimg.png";
import noteLogo from "../assets/img/noteLogo.png";
import Simplifyyourthougths from "../assets/img/syt.png";
import handylist from "../assets/img/hl.png";
import CloudSync from "../assets/img/cs.png";
import Organize from "../assets/img/organize.png";
import Manage from "../assets/img/manage.png";
import QuickNotes from "../assets/img/qn.png";
import Captureideas from "../assets/img/ci.png";
import Securewithlock from "../assets/img/swl.png";
import noteDeckBg from "../assets/img/noteDeckBg.png";
import logo from "../assets/img/logo.png";
import clock1 from "../assets/img/clock1.png";
import clock2 from "../assets/img/clock2.png";
import clock3 from "../assets/img/clock3.png";
import clock4 from "../assets/img/clock4.png";
import Meditate from "../assets/img/Meditate.png";
import Sleep from "../assets/img/Sleep.png";
import Sounds from "../assets/img/Sounds.png";
import Home from "../assets/img/Home.png";
import Player from "../assets/img/Player.png";
import MyNavbar from "./MyNavbar";
import { Element } from "react-scroll";

const Main = () => {
  const [currentImage, setCurrentImage] = useState(Meditate);

  const handleImageChange = (newImage) => {
    setCurrentImage(newImage);
  };

  const NotesDeckURL =
    "https://play.google.com/store/apps/details?id=com.memo.notesdeck&hl=en-IN";
  const ClockMasterURL =
    "https://play.google.com/store/apps/details?id=com.clock.sandtimer&hl=en-IN";
  const SoundscapeURL =
    "https://play.google.com/store/apps/details?id=com.music.focusflow&hl=en-IN";
  const FinanceCalculatorURL =
    "https://play.google.com/store/apps/details?id=com.fin.emi.disccal&hl=en-IN";

  const handleClickSoundScape = () => {
    window.open(SoundscapeURL, "_blank");
  };
  const handleClickClockMaster = () => {
    window.open(ClockMasterURL, "_blank");
  };
  const handleClickFinance = () => {
    window.open(FinanceCalculatorURL, "_blank");
  };
  const handleClickNotesDeck = () => {
    window.open(NotesDeckURL, "_blank");
  };
  return (
    <div className="bg-b">
      <MyNavbar />
      <div className="vector-bg1">
        {/* Home section */}
        <Element name="Home">
          <div>
            <img
              src={bg1}
              className="object-cover h-[180px] w-[100%] lg:h-[300px] xl:h-[380px]"
              alt="bg"
            />
          </div>

          <div className="flex justify-between items-center  px-5  lg:-mt-52 lg:px-10 xl:px-16 md:gap-12">
            <div
              className="invisible lg:visible"
              data-aos="zoom-in-right"
              data-aos-duration="1000"
            >
              <img
                src={lmob}
                className="object-contain  lg:w-[350px] lg:h-[400px]"
                alt="bg"
              />
            </div>

            <div
              className="invisible lg:visible"
              data-aos="zoom-in-left"
              data-aos-duration="1000"
            >
              <img
                src={rmob}
                className="object-contain  lg:w-[350px] lg:h-[400px]"
                alt="bg"
              />
            </div>
          </div>
          <div className="-mt-[11rem] md:-mt-[23rem] px-5 lg:-mt-[180px]">
            <h1 className="text-[40px] lg:text-[54px] font-bold text-center font-Montserrat leading-tight">
              Productivity
            </h1>
            <p className="font-Poppins mt-4 text-[20px] font-normal lg:px-[16rem] xl:px-[23rem] lg:text-justify bg-white">
              Boost Productivity is a dynamic team of talented developers
              committed to simplifying and enhancing your digital life. Our
              passion for innovation has led us to create a suite of four
              exceptional apps that will revolutionize the way you manage your
              time, finances, information, and creative inspiration.
            </p>
          </div>
        </Element>

        {/* Clock Master Section  */}
        <Element name="Clock_Master">
          <div
            className="flex  justify-center lg:items-center mt-6 lg:px-24 lg:mt-12 lg:gap-10"
            data-aos="flip-right"
            data-aos-duration="1000"
          >
            <img
              src={ss1L}
              className="w-[170px] h-[180px] hidden lg:flex"
              alt="bg"
            />
            <img src={clockMaster} className="w-[170px] h-[180px]" alt="bg" />
            <img
              src={ss1L}
              className="w-[170px] h-[180px] hidden lg:flex"
              alt="bg"
            />
          </div>

          <div className="mt-4 px-5 lg:mx-[80px]  xl:mx-[160px]">
            <p className="font-Poppins text-[20px] bg-white">
              Introducing Clock Master, the all-in-one watch app designed to
              enhance your time management and keep you on schedule like never
              before. With a sleek and intuitive interface, Clock Master
              combines essential timekeeping features into a single, powerful
              tool for your wrist. Whether you need an alarm clock, timer, world
              clock, or stopwatch, clock Master has got you covered.
            </p>
          </div>
          <div className="lg:mx-16">
            <h1 className="text-[40px] lg:text-[58px] font-normal font-Montserrat text-center mt-8 lg:mt-12 bg-white">
              What You Will Get
            </h1>
            <div className="flex justify-center items-center px-5 lg:px-24">
              <div>
                <img
                  className="object-contain lg:w-[260px] lg:h-[100%]"
                  src={clock1}
                  alt="bg"
                />
                <p className="text-center  font-Poppins lg:text-[28px] font-normal">
                  World Clock
                </p>
              </div>
              <div className="mt-28 ">
                <p className="text-center  font-Poppins lg:text-[28px] font-normal">
                  Alarm Clock
                </p>
                <img
                  className="object-contain lg:w-[220px] lg:h-[100%]"
                  src={clock2}
                  alt="bg"
                />
              </div>
              <div>
                <img
                  className="object-contain lg:w-[270px] lg:h-[100%]"
                  src={clock3}
                  alt="bg"
                />
                <p className="text-center  font-Poppins lg:text-[28px] font-normal bg-white">
                  Timer
                </p>
              </div>
              <div className="">
                <p className="text-center  font-Poppins lg:text-[28px] font-normal bg-white">
                  Stopwatch
                </p>
                <img
                  className="object-contain lg:w-[288px] lg:h-[100%]"
                  src={clock4}
                  alt="bg"
                />
              </div>
            </div>
            <div className="flex justify-center mt-10 lg:mt-6">
              <img
                src={googlePlay}
                onClick={handleClickClockMaster}
                className="w-[50%] h-[50%] lg:w-[280px] lg:h-[84px] cursor-pointer"
                alt="bg"
              />
            </div>
          </div>
        </Element>

        {/* Note Deck section 1 */}
        <Element name="Notes_Deck">
          <div className="grid lg:grid-cols-2 cal-bg-img lg:h-[470px] px-5 lg:px-24 mt-24 lg:mt-36">
            <div className="lg:p-8 ">
              <div className=" justify-self-center">
                <div className="flex justify-around items-center ">
                  <img
                    src={noteLogo}
                    className="object-contain py-8 lg:py-0 w-[60px] h-[100%] lg:w-[120px] lg:h-[120px]"
                    alt="bg"
                  />

                  <img
                    src={NotesDeckText}
                    className="object-contain w-[220px] lg:w-[330px] h-[100%]"
                    alt="bg"
                  />
                </div>

                <p className="text-white lg:mt-6 capitalize font-Poppins text-[20px]">
                  Notes Deck- your ultimate digital companion for staying
                  organized, jotting down ideas, managing to-do lists, and
                  creating checklists effortlessly. Designed with simplicity and
                  versatility in mind, Notes Deck empowers you to capture and
                  organize your thoughts, tasks, and plans with ease.
                </p>
              </div>
            </div>
            <div className="">
              <img
                src={girlimg}
                className="object-contain lg:object-cover lg:w-[680px] lg:h-[100%]"
                alt="bg"
              />
            </div>
          </div>

          {/* Note Deck section 2 */}
          <div>
            <div className="flex justify-center mt-14 px-5">
              <img
                src={Simplifyyourthougths}
                className="object-contain w-[580px] h-[47px]"
                alt="bg"
              />
            </div>
          </div>

          <div className="grid lg:grid-cols-3 px-5 lg:px-12 pt-8">
            <div className="   ">
              <div className=" flex justify-start">
                <img
                  className="object-contain w-[258px] h-[47px] bg-white"
                  src={handylist}
                  alt="bg"
                />
              </div>
              <div className=" flex justify-end my-3 lg:my-24">
                <img
                  className="object-contain w-[222px] h-[47px]"
                  src={Organize}
                  alt="bg"
                />
              </div>
              <div className=" flex justify-satrt ">
                <img
                  className=" object-contain w-[296px] h-[47px]"
                  src={QuickNotes}
                  alt="bg"
                />
              </div>
            </div>

            <div
              className=" flex justify-center items-center"
              data-aos="flip-left"
              data-aos-duration="1000"
            >
              <img
                className="object-contain w-[468px] h-[332px]"
                src={noteDeckBg}
                alt="bg"
              />
            </div>
            <div className="  ">
              <div className="   ">
                <div className=" flex justify-end">
                  <img
                    className="object-contain w-[278px] h-[47px]"
                    src={CloudSync}
                    alt="bg"
                  />
                </div>
                <div className=" flex justify-start my-3 lg:my-24">
                  <img
                    className="object-contain w-[185px] h-[47px]"
                    src={Manage}
                    alt="bg"
                  />
                </div>
                <div className=" flex justify-end ">
                  <img
                    className="object-contain w-[346px] h-[47px]"
                    src={Captureideas}
                    alt="bg"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-4 lg:mt-14 ">
            <img
              src={Securewithlock}
              className="object-contain w-[433px] h-[47px] bg-white"
              alt="bg"
            />
          </div>

          <div className="flex justify-center mt-6">
            <img
              src={googlePlay}
              onClick={handleClickNotesDeck}
              className="w-[50%] h-[50%] lg:w-[280px] lg:h-[84px] cursor-pointer"
              alt="bg"
            />
          </div>
        </Element>

        {/* Soundscape section  */}
        <Element name="Soundscape">
          <div
            className="flex justify-center mt-20 lg:mt-24 "
            id="Soundscape"
            data-aos="flip-right"
            data-aos-duration="1000"
          >
            <img
              src={soundscape}
              className="bg-white w-[160px] h-[180px]"
              alt="bg"
            />
          </div>
          <div className="flex justify-center mt-8 px-5 lg:px-64 text-center">
            <p className="font-Poppins text-[20px] font-normal capitalize bg-white">
              SoundSpace- your all-encompassing sound sanctuary that caters to
              your auditory needs and elevates your sensory experience. With a
              vast collection of curated sounds for every occasion, this app is
              your go-to destination for crafting the perfect soundscapes to
              suit your mood and preferences.
            </p>
          </div>
          <div className="flex justify-between items-center px-5">
            <div className="bg-img2L hidden lg:flex ">
              <img
                src={ss1L}
                style={{ width: "240px", height: "200px" }}
                alt="bg"
              />
            </div>
            <div>
              <h1 className="text-[40px] lg:text-[58px] font-normal font-Montserrat text-center mt-5 bg-white">
                Get Some Soundscape
              </h1>
              <div className="lg:flex justify-center lg:justify-between items-center px-10 mt-3">
                <button
                  onClick={() => handleImageChange(Meditate)}
                  type="button"
                  className={
                    currentImage === Meditate
                      ? "transition duration-700 ease-in-out text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2"
                      : "text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2"
                  }
                >
                  Meditate
                </button>
                <button
                  onClick={() => handleImageChange(Sleep)}
                  type="button"
                  className={
                    currentImage === Sleep
                      ? "transition duration-700 ease-in-out text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2"
                      : "text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2"
                  }
                >
                  Sleep
                </button>
                <button
                  onClick={() => handleImageChange(Sounds)}
                  type="button"
                  className={
                    currentImage === Sounds
                      ? "transition duration-700 ease-in-out text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2"
                      : "text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2"
                  }
                >
                  Sound
                </button>
                <button
                  onClick={() => handleImageChange(Home)}
                  type="button"
                  className={
                    currentImage === Home
                      ? "transition duration-700 ease-in-out text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2"
                      : "text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2"
                  }
                >
                  Home
                </button>
                <button
                  onClick={() => handleImageChange(Player)}
                  type="button"
                  className={
                    currentImage === Player
                      ? "transition duration-700 ease-in-out text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2"
                      : "text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2"
                  }
                >
                  Player
                </button>
              </div>
            </div>

            <div className="bg-img2R hidden lg:flex">
              <img
                src={ss1R}
                style={{ width: "240px", height: "200px" }}
                alt="bg"
              />
            </div>
          </div>
          <div
            className="my-10 flex justify-center items-center transition duration-700 transform image-container"
            data-aos="flip-left"
            data-aos-duration="1000"
          >
            <img
              className="w-[80%] lg:w-[55%] transition-opacity duration-500 ease-in-out transform "
              src={currentImage}
              alt={`${currentImage}`}
            />
          </div>
          <div className="flex justify-center lg:mt-14">
            <img
              src={googlePlay}
              onClick={handleClickSoundScape}
              className="w-[50%] h-[50%] lg:w-[280px] lg:h-[84px] cursor-pointer"
              alt="bg"
            />
          </div>
        </Element>

        {/* Calculator section  */}
        <Element name="Finance_Calculator">
          <div className="lg:grid lg:grid-cols-2 cal-bg-img mt-24">
            <div className="hidden lg:grid">
              <img
                src={handMob}
                className="object-cover  lg:w-[90%] lg:h-[100%]"
                alt="bg"
              />
            </div>
            <div className="p-5 lg:p-8 lg:pl-0 lg:px-24">
              <div className=" justify-self-start">
                <div className="flex items-center ml-2 lg:ml-10 lg:gap-8">
                  <img
                    src={calLogo}
                    style={{ width: "120px", height: "120px" }}
                    alt="bg"
                  />
                  <div className="ml-2">
                    <h1 className="text-[30px] lg:text-[50px] font-semibold font-Montserrat text-white leading-[1]">
                      Finance
                    </h1>
                    <h1 className="text-[30px] lg:text-[50px] font-semibold font-Montserrat text-white lg:leading-[1]">
                      Calculator
                    </h1>
                  </div>
                </div>
                <p className="text-white font-Poppins text-[19px] capitalize mt-4">
                  Meet Finance Calculator, your all-in-one financial tool
                  designed to simplify complex calculations and help you make
                  informed decisions about your loans and finances. This
                  versatile app offers a range of essential features to manage
                  loans, payments, currency conversions, and even helps you find
                  the nearest ATM when you're on the go.
                </p>
              </div>
            </div>
          </div>
          <div className="py-8 ">
            <h1 className="text-center text-[40px] lg:text-[56px] font-normal font-Rubik bg-white">
              Be Kind to Your Mind
            </h1>
            <p className="text-center text-[20px] lg:text-[24px] font-Rubik font-normal bg-white">
              Let us work from your side
            </p>
          </div>
          <div className="grid lg:grid-cols-3 px-6 lg:px-24">
            <div className="">
              <div>
                <div className="flex justify-start items-center gap-6">
                  <div className="border rounded-[50%] bg-[#879DFF] flex justify-center items-center w-[50px] h-[50px]">
                    <img
                      src={signimg}
                      style={{ width: "25px", height: "25px" }}
                      alt="bg"
                    />
                  </div>
                  <h1 className="text-[24px] font-semibold font-Montserrat">
                    EMI Calculator
                  </h1>
                </div>
                <p className="text-[12px] font-medium font-Montserrat mt-4 bg-white">
                  Calculate your Equated Monthly Installments for loans with
                  various interest rates, tenures, and down payments. Visualize
                  your repayment schedule to understand how each payment
                  contributes to principal and interest.
                </p>
              </div>

              <div className="my-16">
                <div className="flex justify-start items-center gap-5">
                  <div className="border rounded-[50%] bg-[#879DFF] flex justify-center items-center w-[50px] h-[50px]">
                    <img
                      src={payment}
                      style={{ width: "25px", height: "25px" }}
                      alt="bg"
                    />
                  </div>
                  <h1 className="text-[24px] font-semibold font-Montserrat bg-white">
                    Pre-Payments
                  </h1>
                </div>
                <p className="text-[12px] font-medium font-Montserrat mt-4 bg-white">
                  Determine the impact of making extra payments on your loans.
                  Find out how pre-payments can reduce your interest and loan
                  tenure.
                </p>
              </div>

              <div className="bg-white mb-4">
                <div className="flex justify-start items-center gap-5">
                  <div className="border rounded-[50%] bg-[#879DFF] flex justify-center items-center w-[50px] h-[50px]">
                    <img
                      src={money}
                      style={{ width: "25px", height: "25px" }}
                      alt="bg"
                    />
                  </div>
                  <h1 className="text-[24px] font-semibold font-Montserrat">
                    Currency Exchange
                  </h1>
                </div>
                <p className="text-[12px] font-medium font-Montserrat mt-4">
                  Effortlessly convert between currencies for travel or
                  investment purposes. Access real-time exchange rates for
                  accurate conversions.
                </p>
              </div>
            </div>
            <div className="  flex justify-center items-center">
              <img
                src={calPhone}
                style={{
                  width: "294px",
                  height: "600px",
                  objectFit: "contain",
                }}
                alt="bg"
              />
            </div>
            <div className=" ">
              <div className="bg-white">
                <div className="flex justify-start items-center gap-5">
                  <div className="border rounded-[50%] bg-[#879DFF] flex justify-center items-center w-[50px] h-[50px]">
                    <img
                      src={bank}
                      style={{ width: "25px", height: "25px" }}
                      alt="bg"
                    />
                  </div>
                  <h1 className="text-[24px] font-semibold font-Montserrat">
                    Nearest Bank
                  </h1>
                </div>
                <p className="text-[12px] font-medium font-Montserrat mt-4 ">
                  Locate nearby banks using GPS or by searching specific
                  locations. Get directions to the nearest branches or Banks,
                  ensuring you never run out of cash.
                </p>
              </div>

              <div className="my-16">
                <div className="flex justify-start items-center gap-5">
                  <div className="border rounded-[50%] bg-[#879DFF] flex justify-center items-center w-[50px] h-[50px]">
                    <img
                      src={decision}
                      style={{ width: "25px", height: "25px" }}
                      alt="bg"
                    />
                  </div>
                  <h1 className="text-[24px] font-semibold font-Montserrat">
                    Compare Loan
                  </h1>
                </div>
                <p className="text-[12px] font-medium font-Montserrat mt-4">
                  Compare multiple loan offers side by side to make informed
                  decisions. Evaluate interest rates, monthly payments, and
                  overall costs to choose the best loan option.
                </p>
              </div>

              <div>
                <div className="flex justify-start items-center gap-5">
                  <div className="border rounded-[50%] bg-[#879DFF] flex justify-center items-center w-[50px] h-[50px]">
                    <img
                      src={atm}
                      style={{ width: "25px", height: "25px" }}
                      alt="bg"
                    />
                  </div>
                  <h1 className="text-[24px] font-semibold font-Montserrat">
                    Nearest ATM
                  </h1>
                </div>
                <p className="text-[12px] font-medium font-Montserrat mt-4 bg-white">
                  Locate nearby ATMs using GPS or by searching specific
                  locations. Get directions to the nearest branches or ATMs,
                  ensuring you never run out of cash.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-14">
            <img
              src={googlePlay}
              onClick={handleClickFinance}
              className="w-[50%] h-[50%] lg:w-[280px] lg:h-[84px] cursor-pointer"
              alt="bg"
            />
          </div>

          {/* Footer  */}
          <div className="grid lg:grid-cols-3 bg-[#1E1E1E] mt-12 lg:mt-24 px-5 lg:px-24 py-4 border">
            <div className="hidden lg:flex justify-start items-center">
              <img
                src={logo}
                className="object-contain w-[65px] h-[43px]"
                alt="bg"
              />
            </div>
            <div className="  flex justify-center items-center">
              <img
                src={logo}
                className="object-contain w-[35px] h-[100%] mr-4 lg:hidden"
                alt="bg"
              />
              <p className="text-white text-[18px] lg:text-[20px] font-normal font-Poppins">
                <a href="mailto:contact@boostproductivity.com">
                  Contact@boostproductivity.com
                </a>
              </p>
            </div>
            <div className="hidden lg:flex justify-end items-center">
              <img
                src={googlePlay}
                onClick={handleClickClockMaster}
                className="object-contain w-[143px] h-[43px] cursor-pointer"
                alt="bg"
              />
            </div>
          </div>
        </Element>
      </div>
    </div>
  );
};

export default Main;
